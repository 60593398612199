/* Container principale */
.insights-section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    perspective: 1000px; /* Effetto 3D */
}

/* Container con effetto 3D */
.insights-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.1), /* Ombra leggera */
        0 16px 32px rgba(0, 0, 0, 0.1); /* Ombra più intensa */
    transform-style: preserve-3d; /* Preserva il contesto 3D */
    transform: rotateX(1deg) rotateY(2deg); /* Leggero effetto di inclinazione */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Effetto hover */
.insights-content:hover {
    transform: rotateX(0deg) rotateY(0deg) translateY(-10px); /* Sollevamento */
    box-shadow: 
        0 8px 16px rgba(0, 0, 0, 0.15), 
        0 24px 48px rgba(0, 0, 0, 0.2); /* Ombre più intense */
}

/* Layout delle immagini */
.image-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    width: 100%;
}

/* Immagine principale */
.image-large {
    grid-row: span 2;
}

.image-large img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera sulle immagini */
}

/* Immagini più piccole */
.image-small-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image-small img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera sulle immagini */
}

/* Descrizione */
.insights-description {
    margin-top: 20px;
    font-size: 1em;
    color: #555;
    text-align: center;
}

/* Responsiveness */
@media (max-width: 768px) {
    .image-grid {
        grid-template-columns: 1fr;
    }

    .image-large {
        grid-row: span 1;
    }

    .image-small-container {
        flex-direction: row;
        gap: 10px;
    }
}
