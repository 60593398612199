@font-face {
  font-family: "LTWaveBold";
  src: url("../../../asset/fonts/LT_wave/LTWave-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

.presentation-container {
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
}

.presentation-title {
  font-size: 2rem;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "LTWaveBold", sans-serif;
}

.presentation-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.p-card-title {
  font-family: "LTWaveBold", sans-serif;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  cursor: pointer;
  perspective: 1000px;
  /* Per l'effetto 3D */
}

.card:hover {
  transform: translateY(-37px) rotateX(5deg) rotateY(-5deg);
  /* Sollevamento e rotazione */
  box-shadow: 0 8px 10px #f9e89f9f;
  /* Ombra più intensa */
}

.contact-button {
  background-color: #c2ebe5;
  color: black;
  border: 2px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 2%;
  display: inline-block;
  text-decoration: none;
}

.contact-button:hover {
  background-color: #afc0df;
  border-color: none;
}

.top_descrbtn {
  position: relative;
}

/* STILE DIFFERENTE IN CONTRASTRO AL TEMA DI CAMBIO */
.switch_button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #c2ebe5;
  color: black;
  border: 2px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.switch_button:hover {
  background-color: #afc0df;
  border-color: none;
}

.presentation-container {
  transition: background-color 0.5s ease, color 0.5s ease;
}

.blue-theme {
  background-color: #293547;
  color: white;
}

.green-theme {
  background-color: #4db7a6;
  color: black;
}

.animating .card-container {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* FORM STYLE */

.multi-step-form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.progress-bar-container {
  height: 10px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(to right, #218838, #293547);
  transition: width 0.3s ease;
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-field input,
.form-field select,
.form-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.back-button,
.next-button {
  background: #293547;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover,
.next-button:hover {
  background-color: #293547;
  transform: scale(1.05);
}
