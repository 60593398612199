.slideshow-container {
    position: relative;
    max-width: 100%;
    max-height: 500vh;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slide {
    display: none;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .slide.active {
    display: block;
    opacity: 1;
  }
  
  .carousel-image {
    width: 100%;
    height: auto; /* Mantiene il rapporto d'aspetto delle immagini */
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 44px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  @font-face {
    font-family: sans-serif;
    src: url("../../../asset/fonts/LT_wave/LTWave-Bold.otf");
    font-weight: bold;
    font-style: italic;
  }