/* src/style/DataMastery.css */
.data-mastery-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.header-section {
    text-align: center;
    margin-bottom: 40px;
}

.badge {
    display: inline-block;
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
    color: #000;
}

h1 {
    font-size: 2.5rem;
    color: #000;
}

p {
    max-width: 600px;
    margin: 0 auto;
    color: #555;
}


@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }

    p {
        font-size: 0.9rem;
    }
}
