@font-face {
  font-family: 'LTWaveBold';
  src: url("../src/asset/fonts/LT_wave/LTWave-Bold.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
