.home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.home_container > div {
  width: 100%;
}

/* Overlay per sfocare lo sfondo */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Oscura lo sfondo */
  backdrop-filter: blur(8px); /* Applica l'effetto sfocato */
  z-index: 999; /* Assicurati che sia sopra lo sfondo */
}

.cookie-popup {
  position: fixed; /* Mantiene il popup fisso in basso */
  bottom: 0px;
  right: 0;
  background-color: #fff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  width: 17%;
  display: flex; /* Aggiunto Flexbox */
  justify-content: space-between; /* Spaziatura tra il testo e il pulsante */
  align-items: center; /* Allinea verticalmente il pulsante con il testo */
  border: 1px solid rgba(169, 169, 169, 0.7);
}

.cookie-popup p {
  margin: 0; /* Rimuove i margini predefiniti del paragrafo */
}

.cookie-popup button {
  margin: 0; /* Rimuove i margini per evitare spazi extra */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background-color: #28a745;
  color: white;
}

.cookie-popup .accept {
  background-color: #28a745;
  color: white;
}

.cookie-popup .reject {
  background-color: #dc3545;
  color: white;
}

/* Responsiveness */
@media (max-width: 768px) {
  .cookie-popup {
    width: 60%;
  }
}
