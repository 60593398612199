.background-gray {
  backdrop-filter: blur(30px);
  padding: 20px;
}

.container_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  max-width: 1300px;
  margin: auto;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.title_carduse {
  font-size: 24px;
  font-family: 'LTWaveBold', sans-serif;
}

.descrip_carduse {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 20px;
}

/* Styling per ogni div con effetto Glassmorphism */
.first_item,
.second_item,
.third_item,
.four_item {
  padding: 65px;
  height: 303px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  color: white;
  background: #293547;
  backdrop-filter: blur(80px);
  border-radius: 20px;
  border-top: 1.5px solid rgba(0, 0, 0, 0.171);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  cursor: pointer;
  margin: 20px;
  position: relative;
  /* Mantieni la posizione relativa per il contenitore */
}

/* Effetto al passaggio del mouse (hover) */
.first_item:hover,
.second_item:hover,
.third_item:hover,
.four_item:hover {
  transform: scale(1.2) translateY(-10px);
  /* Solo ingrandimento e sollevamento */
  z-index: 10;
  /* Porta la card in primo piano */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* Ombra più marcata */
}

/* Colore del testo per ogni div */
.first_item p,
.second_item p,
.third_item p,
.four_item p {
  color: white;
}

/* Stile dei titoli */
.grid-item h3 {
  font-size: 24px;
  color: #293547;
  margin-bottom: 10px;
}

/* Altri stili (linee verticali) */
.line-vertical-bottom,
.line-vertical-top {
  position: absolute;
  background: #fff;
}

.line-vertical-bottom {
  bottom: 0;
  right: 1em;
}

.line-vertical-top {
  top: 0;
  left: 1em;
}

@media (max-width: 768px) {
  .title_carduse {
    font-size: 20px;
  }

  .descrip_carduse {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 10px;
  }

  .container_grid {
    grid-template-columns: 1fr;
  }
}