@font-face {
  font-family: 'LTWaveBold';
  src: url("../asset/fonts/LT_wave/LTWave-Bold.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
}


/* Sezione della descrizione dell'azienda */
.company-description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    background-color: #f4f4f4;
  }

  .title_about{
    font-family: 'LTWaveBold', sans-serif !important;
  }
  
  .description-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    align-items: center;
  }
  
  .description-text {
    flex: 1;
    padding-right: 20px;
  }
  
  .description-text h2 {
    font-size: 36px;
    color: #293547;
    font-family: 'PT Sans', sans-serif;
  }
  
  .description-text p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
  }
  
  .description-image {
    flex: 1;
    padding-left: 20px;
  }
  
  .description-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  

  @media (max-width: 768px) {
    .description-container {
      flex-direction: column;      /* Cambia il layout in verticale */
      text-align: center;           /* Centra il testo */
    }
  
    .description-text {
      padding-right: 0;             /* Rimuove il padding destro */
      padding-bottom: 20px;         /* Aggiunge spazio tra testo e immagine */
    }
  
    .description-image {
      padding-left: 0;              /* Rimuove il padding sinistro */
    }
  
    .description-text h2 {
      font-size: 28px;              /* Riduce la dimensione del titolo */
    }
  
    .description-text p {
      font-size: 16px;              /* Riduce la dimensione del testo */
    }
  }