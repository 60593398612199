.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 10px 20px;
}

.navbar_start {
    flex: 1;
}

.navbar_logo {
    height: 80px;
}

/* Menu principale per schermi grandi */
.nav_menu {
    display: flex;
    list-style: none;
    justify-content: center;
    flex: 2;
    gap: 20px;
}

.nav_item {
    position: relative;
}


.nav_link {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 15px;
}

.nav_link:hover {
    color: #4db7a6;
}

/* Assicura che il dropdown abbia un layout corretto */
.custom-dropdown .dropdown-menu {
    display: block;
    position: absolute;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Evita che i link del dropdown ereditino lo stile della navbar */
.custom-dropdown .dropdown-item {
    display: block;
    padding: 10px 15px;
    color: black;
    text-decoration: none;
}


.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    color: black;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
    min-width: 150px;
    border-radius: 5px;
    z-index: 1000;
  }
  
  .dropdown-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    text-decoration: none;
    color: black;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .dropdown-item a{
    text-decoration: none;
    color: black;
  }
  
  .dropdown-item:hover {
    background: #293547;
    color: white;
  }

  

/* Pulsante aiuto (per desktop) */
.navbar-button {
    background-color: #293547;
    color: white;
    border: 2px solid white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.hamburger_menu {
    display: none;
}

/* BUTTON TRANSLATE */

.language_switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.btn-translate-primary {
    background-color: #c2ebe5;
    color: black;
    border: 1.5px solid rgb(7, 7, 7);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    margin-top: 2%;
    display: inline-block;
    text-decoration: none;
}

.btn-translate-primary:hover {
    background-color: #afc0df;
    border-color: none;
}

.btn-translate-secondary {
    background-color: #c2ebe5;
    color: black;
    border: 1.5px solid rgb(7, 7, 7);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    margin-top: 2%;
    display: inline-block;
    text-decoration: none;
}

.btn-translate-secondary:hover {
    background-color: #afc0df;
    border-color: none;
}


@media (max-width: 768px) {

    /* Nascondi il menu desktop e mostra il menu hamburger */
    .nav_menu {
        display: none;
    }

    /* Visualizza l'icona hamburger */
    .hamburger_menu {
        display: block;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
    }

    /* Mostra il menu mobile quando è attivo */
    .nav_menu.active {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    /* Menu mobile (overlay) */
    .mobile_menu_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
    }

    /* Pulsante per chiudere il menu */
    .close_menu_button {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 30px;
        color: #333;
        cursor: pointer;
    }


    .mobile_menu_content {
        text-align: center;
    }

    .mobile_logo {
        margin-bottom: 20px;
    }

    .mobile_nav_menu {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobile_nav_item {
        margin: 20px 0;
    }

    .mobile_nav_item a {
        font-size: 20px;
        text-decoration: none;
        color: #333;
        font-weight: 500;
    }

    .mobile_nav_item a:hover {
        color: #4db7a6;
    }

    .nav_btn {
        display: none;
    }
}