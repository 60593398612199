.features-cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.feature-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    width: 100%;
    max-width: 300px;
    margin: auto;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.feature-icon {
    width: 50px;
    height: 50px;
}

.feature-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
}

.feature-description {
    color: #555;
    margin-bottom: 15px;
    font-size: 0.9em;
}

.explore-button {
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s;
}

.explore-button:hover {
    background-color: #000;
    color: #fff;
}