.data-mastery-container {
    text-align: left; /* Allinea il testo a sinistra */
    margin: 0 auto;   /* Mantiene un margine centrato se necessario */
    padding: 20px;    /* Aggiungi un po' di padding per migliorare l'aspetto */
}


.data-mastery-container p {
    margin: 0;
}

.data-mastery-container h2 {
    text-align: left;
}