.footer {
  background-color: #f8f9fa;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
}

.footer-top {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.footer-logo {
  text-align: center;
  align-items: center;
}

.footer-logo img {
  height: 130px;
  margin-bottom: 10px;
}

.title_social {
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.footer-social i {
  font-size: 50px;
  color: #0063c2;
  border: 1px solid black;
  border-radius: 7px;
  transition: transform 0.3s ease;
}

.footer-social i:hover {
  transform: scale(1.2);
}

.footer-bottom {
  border-top: 1px solid #34495e;
  padding-top: 10px;
  font-size: 14px;
}

.scroll-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #293547;
  border: none;
  color: #fff;
  border-radius: 50%;
  padding: 10px;
  font-size: 24px;
}

.scroll-top-btn:hover {
  background-color: #16a085;
}

.footer-section {
  flex: 1;
  margin: 10px;
  /* Spaziatura tra le sezioni */
}

.footer-section-right {
  flex: 1;
  margin: 10px;
  /* Spaziatura tra le sezioni */
  text-align: right;
  /* Allineamento a destra per le sezioni di contatti e orari */
}

.title {
  color: black;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.footer-left,
.footer-right {
  flex: 1;
  padding: 10px;
}

.footer-section {
  margin-bottom: 15px;
}

.footer-section a {
  text-decoration: none;
  color: #555;
}

.title {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Responsive */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    /* Disposizione verticale */
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    width: 100%;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    /* Larghezza completa per ogni sezione */
    text-align: center;
    /* Centra il testo */
  }

  .footer-section {
    margin-bottom: 20px;
  }
}
