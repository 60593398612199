@font-face {
  font-family: 'PT Sans';
  src: url('../../src/asset/fonts/PTSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'PT Sans';
  src: url('../../src/asset/fonts/PTSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'LT Wave';
  src: url('../../src/asset/fonts/LT_wave/LTWave-Bold.otf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'LT Wave';
  src: url('../../src/asset/fonts/LT_wave/LTWave-Regular.otf') format('truetype');
  font-weight: 400;
}


/* .values-section {
  font-family: 'PT Sans', sans-serif;
} */

/* Sezione dei valori */
.values-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
  }
  
  .values-section h2 {
    font-size: 36px;
    color: #293547;
    margin-bottom: 40px;
    font-family: 'LT Wave', sans-serif;
  }
  
  .values-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .value-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 180px; /* Puoi modificare la larghezza in base alle tue necessità */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s; /* Effetto di zoom */
  }
  
  .value-card:hover {
    transform: scale(1.05); /* Zoom al passaggio del mouse */
  }
  
  .value-card h3 {
    font-size: 20px;
    color: #4db7a6;
    font-family: 'LT Wave', sans-serif;
  }
  
  .value-card p {
    font-size: 14px;
    color: #666;
  }
  
  .title_about{
    display: flex;
    align-items: center;
    flex-direction: column;
  }