/* Header - Titolo e descrizione */
.support-header {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
}

.support-header h2 {
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.support-header p {
  font-size: 16px;
  color: #555;
  margin: 0 auto;
  max-width: 600px;
}

/* Aggiorna il contenitore principale per separare header e contenuto */
.support-page {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

/* Contenitore principale */
.support-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}

.form-org {
  display: flex;
  /* align-items: center; */
  padding-bottom: 20px;
}

/* Sezione sinistra - Form */
.support-left {
  flex: 1;
  max-width: 600px;
  background: #f9f9f9ad;
  padding: 40px;
  border-radius: 35px;
  border: 1.5px solid black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.support-left h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.support-left p {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Form */
.support-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bolder;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.form-group textarea {
  resize: none;
  height: 100px;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
}

.checkbox-container input {
  margin-top: 3px;
}

.checkbox-container a {
  color: #007bff;
  text-decoration: none;
}

.checkbox-container a:hover {
  text-decoration: underline;
}

/* Pulsante */
.button-container {
  display: flex;
  justify-content: center;
}

.submit-btn {
  background-color: #293447;
  color: white;
  font-weight: bolder;
  border: 0.5px solid rgba(95, 140, 238, 0.39);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 15px;
  width: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #afc0df;
  font-weight: bolder;
  color: rgba(0, 0, 0, 0.877);
}

/* Sezione destra - Informazioni */
.support-right {
  flex: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  gap: 20px;
}

.contact-info,
.social-info {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info h3,
.social-info h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-info p,
.social-info p {
  font-size: 14px;
}

.contact-info a,
.social-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover,
.social-info a:hover {
  text-decoration: underline;
}

/* Responsività */
/* Responsività */
@media (max-width: 768px) {
  .form-org {
    flex-direction: column; /* Dispone i div uno sotto l'altro */
    align-items: center; /* Allinea i contenitori al centro */
  }

  .support-left .support-right {
    max-width: 100%; /* Imposta la larghezza al 100% per sfruttare tutto lo spazio disponibile */
  }
  .support-right {
    margin-top: 4%;
    padding-left: 0;
  }
}
